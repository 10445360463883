import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
import tecne_logo from '../../assets/images/projects/service/tecne/tecne-logo.jpg'

const ServiceDesignTecnePrivate = props => (
  <Layout>
    <Helmet>
      <title>Service Design - Tecne Private</title>
      <meta name="Service Design - Tecne Private" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.tecnecover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">UX STRATEGY</span>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <span className="tag">PRODUCT DESIGN</span>
            <span className="tag">BRANDING</span>
            <h1>tecne.</h1>
            <h3>
              A physical-digital IoT product to unlock a new chapter in training
              for combat sports. An underglove, to captures real-time data of
              athletes during their training, accompanied by a mobile app that
              provides athletes with actionable insights so that they can make
              actual gains from their training routines and improve quickly as
              they prepare for their upcoming fights.
            </h3>
          </header>
          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  * This project was carried out while working at the design
                  agency, THINGS, in Milan. Get in touch with me for more
                  details related to this project; such as my role, the problems
                  i faced, the processes i used and how i addressed the
                  challenges.
                </p>
                <p>
                  <a href="/say-hello">
                    <u>Get in touch</u>
                  </a>
                  with me for more details related to this project; such as my
                  role, the problems i faced, the processes i used and how i
                  addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.things_logo.childImageSharp.fluid} /> */}
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products and services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>
            </div>
          </section>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  We had to design a physical-digital product for people
                  training for combat sports of MMA and Kickboxing. By using the
                  power of IoT technologies we aimed to design a product that
                  would give these fighters access to meaningful and actionable
                  insights about how they are training, which otherwise would be
                  inaccessible to them and their trainers. To do this
                  successfully, it was crucial that we designed an experience
                  that would blend in with their training routine with ease.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2018 - 2019
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 6 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Designing a physical-digital
                  product that assists athletes who are training for combat
                  sports.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of THINGS, Milan
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Benchmarking competitors, planning &
                  preparing for contextual interviews, mapping current & future
                  user journey, user flows of the mobile app, wireframing of
                  mobile app & website, UI design consultation, prototyping
                  mobile app on Adobe XD.
                </span>
                <div className="challenge-client">
                  <h5>CLIENT</h5>
                  <div className="client-image">
                    <img className="span-3" src={tecne_logo} alt="tecne logo" />
                  </div>
                </div>
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.tecnechallenge.childImageSharp.fluid} />
            </div>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
          </section>
          <section className="private">
            <div className="inner">
              <div className="private-logo" />
              <h3>This project is private.</h3>
              <p className="private-text">
                get in touch with me on <u>sagar.satam25@gmail.com</u> <br />
                to discuss about this project and get access to view the entire
                project.
              </p>
            </div>
          </section>
          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to check out my
              <br />
              <Link to="/#one">featured work.</Link>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-mercedes">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">electric car charging service.</div>
                <div className="text">
                  A seamless parking & charging solution for Mercedes Benz &
                  Smart Electric Car owners living in urban areas.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-hive-part-1">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hive (part 1).</div>
                <div className="text">
                  Unique experiences that bring people together over home cooked
                  meals hosted by local chefs in neighbourhoods.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                    <li>BUSINESS DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignTecnePrivate

export const fluidTecnePrivateImage = graphql`
  fragment fluidTecnePrivateImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tecnecover: file(
      relativePath: { eq: "projects/service/tecne/cover/tecne-cover-image.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecne_logo: file(
      relativePath: { eq: "projects/service/tecne/tecne-logo.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecnechallenge: file(
      relativePath: { eq: "projects/service/tecne/challenge/1.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess2: file(
      relativePath: { eq: "projects/service/tecne/process/2.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess3: file(
      relativePath: { eq: "projects/service/tecne/process/3.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess4: file(
      relativePath: { eq: "projects/service/tecne/process/4.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess5: file(
      relativePath: { eq: "projects/service/tecne/process/5.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess6: file(
      relativePath: { eq: "projects/service/tecne/process/6.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess7: file(
      relativePath: { eq: "projects/service/tecne/process/7.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess8: file(
      relativePath: { eq: "projects/service/tecne/process/8.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess9: file(
      relativePath: { eq: "projects/service/tecne/process/9.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess10: file(
      relativePath: { eq: "projects/service/tecne/process/10.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess11: file(
      relativePath: { eq: "projects/service/tecne/process/11.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess12: file(
      relativePath: { eq: "projects/service/tecne/process/12.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess13: file(
      relativePath: { eq: "projects/service/tecne/process/13.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess14: file(
      relativePath: { eq: "projects/service/tecne/process/14.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess15: file(
      relativePath: { eq: "projects/service/tecne/process/15.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess16: file(
      relativePath: { eq: "projects/service/tecne/process/16.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess17: file(
      relativePath: { eq: "projects/service/tecne/process/17.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess18: file(
      relativePath: { eq: "projects/service/tecne/process/18.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess19: file(
      relativePath: { eq: "projects/service/tecne/process/19.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess20: file(
      relativePath: { eq: "projects/service/tecne/process/20.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess21: file(
      relativePath: { eq: "projects/service/tecne/process/21.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess22: file(
      relativePath: { eq: "projects/service/tecne/process/22.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess23: file(
      relativePath: { eq: "projects/service/tecne/process/23.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess24: file(
      relativePath: { eq: "projects/service/tecne/process/24.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess25: file(
      relativePath: { eq: "projects/service/tecne/process/25.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess26: file(
      relativePath: { eq: "projects/service/tecne/process/26.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess27: file(
      relativePath: { eq: "projects/service/tecne/process/27.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess28: file(
      relativePath: { eq: "projects/service/tecne/process/28.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess29: file(
      relativePath: { eq: "projects/service/tecne/process/29.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess30: file(
      relativePath: { eq: "projects/service/tecne/process/30.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess31: file(
      relativePath: { eq: "projects/service/tecne/process/31.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess32: file(
      relativePath: { eq: "projects/service/tecne/process/32.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess33: file(
      relativePath: { eq: "projects/service/tecne/process/33.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess34: file(
      relativePath: { eq: "projects/service/tecne/process/34.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess35: file(
      relativePath: { eq: "projects/service/tecne/process/35.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess36: file(
      relativePath: { eq: "projects/service/tecne/process/36.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess37: file(
      relativePath: { eq: "projects/service/tecne/process/37.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess38: file(
      relativePath: { eq: "projects/service/tecne/process/38.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess39: file(
      relativePath: { eq: "projects/service/tecne/process/39.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess40: file(
      relativePath: { eq: "projects/service/tecne/process/40.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess41: file(
      relativePath: { eq: "projects/service/tecne/process/41.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess42: file(
      relativePath: { eq: "projects/service/tecne/process/42.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess43: file(
      relativePath: { eq: "projects/service/tecne/process/43.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneprocess44: file(
      relativePath: { eq: "projects/service/tecne/process/44.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    tecneoutcome45: file(
      relativePath: { eq: "projects/service/tecne/outcome/45.jpg" }
    ) {
      ...fluidTecnePrivateImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidTecnePrivateImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidTecnePrivateImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidTecnePrivateImage
    }
  }
`
